<template lang="pug">
include ../pug/svg
section#user
	nav.navbar.sticky
		div
			ul
				//-li
					router-link(:to="{name:'ManagePeopleUserOverview', params:{userId:userId}}") Dashboard
				li
					router-link(:to="{name:'ManagePeopleUserProfile', params:{userId:userId}}") Profile
				//-li
					router-link(:to="{name:'ManagePeopleUserLearning', params:{userId:userId}}") {{terminology('course','course','plural')}}
				//-li
					router-link(:to="{name:'ManagePeopleUserActivity', params:{userId:userId}}") Activity
			button.but.pri.spinner(:class="{spin:saving}" type="button" :disabled="saving||!canSave" @click="saveUser") Save
				+svg(svg-filename="spinner" aria-hidden="true" alt="Processing")
			button.but.pri.spinner(:class="{spin:resetting}" type="button" :disabled="resetting||!canCancel" @click="resetUser") Cancel
				+svg(svg-filename="spinner" aria-hidden="true" alt="Processing")
			
	router-view(v-slot="{Component}")
		transition(name="section" mode="out-in" enter-active-class="hide" leave-active-class="hide" appear)
			component(:is="Component" :user="user" :cancelled="cancelled" @storeUpdated="storeUpdated($event)")
</template>

<script>
import UserService from '../services/UserService';
	
export default {
	name: 'ManagePeopleUser',
	data() {
		return {
			userId: parseInt(this.$route.params.userId),
			canSave: false,
			canCancel: false,
			cancelled: false,
			saving: false,
			resetting: false,
		}
	},
	computed: {
		user() {
			// get current user from store
			return this.$store.getters['users/getUser'](this.userId);
		},
	},
	methods: {
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		storeUpdated({valid}) {
			if (!this.cancelled) {
				// changes made in child component, so enable/disable save button based of validity of change
				this.canSave = valid;
				this.canCancel = true;				
			}
		},
		async saveUser() {
			// save store to db
			try {
				this.saving = true;
				const delay = this.$store.getters['gui/delay'];
				
				// copy store to local var for cleanse
				const user = {...this.user};
				user.zoomUserEmail = user.userOrganisations[0].zoomUserEmail;
				
//				delete user.userOrganisations;
				delete user.userId;
				
				// save user to db
				const response = await UserService.updateUser(user);
				
				await delay;
				
				if (response.status === 200) {					
					this.saving = false;
					this.canSave = false;
					this.canCancel = false;
				}
				
			} catch(err) {
				// handle error
				console.log('saveUser()>err:', err.response.status, err.response.data.message);
			}
		},
		async resetUser() {
			this.cancelled = true;
			this.resetting = true;
			this.canSave = false;
			this.canCancel = false;
			
			const delay = this.$store.getters['gui/delay'];			
			
			// reset user from db
			await this.$store.dispatch('users/dbUsers');
			
			await delay;
			
			// disable save until changes
			this.cancelled = false;
			this.resetting = false;
		}
	}
}
</script>

<style lang="scss" scoped>
</style>
